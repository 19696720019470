export const isInAppBrowser = () => {
  return navigator.userAgent.match(
    /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|iPhone(.*)CriOS|iPhone(.*)FxiOS|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS\/[^1]/i,
  );
};
export const isSamsungBrowser = () => {
  return navigator.userAgent.match(/SamsungBrowser\/[^1]/i);
};
export const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return 'Android';
  } else if (/iPad|iPhone|iPod/.test(ua)) {
    return 'iOS';
  } else if (/Mac/.test(ua) && navigator.maxTouchPoints === 5) {
    return 'iOS';
  }
  return 'Other';
};
export const isAppInstalled = () => {
  let displayMode = 'browser';
  const mqStandAlone = '(display-mode: standalone)';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    displayMode = 'standalone';
  }
  return displayMode === 'standalone';
};
export const isIOSInstalled = () => {
  const iOSCanInstall = 'standalone' in window.navigator;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.navigator.standalone) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const iOSIsInstalled = window.navigator.standalone === true;
    if (iOSCanInstall && iOSIsInstalled) {
      return true;
    }
  }
  return false;
};
