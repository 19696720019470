import { useReducer } from 'react';

import { LoginContext } from './LoginContext';
import { LoginInitialData } from './LoginInitialData';
import { loginReducer } from './LoginReducer';

const LoginProvider = ({ children }) => {
  const [state, dispatch] = useReducer(loginReducer, LoginInitialData);
  const value = { state, dispatch };
  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
};

export default LoginProvider;
