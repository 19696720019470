import axios from 'axios';

export const requestKakaoLogin = () => {
  const KAKAO_REST_API_KEY = process.env.KAKAO_REST_API_KEY;
  const KAKAO_REDIRECT_URI = process.env.KAKAO_REDIRECT_URI;
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code`;
  window.open(KAKAO_AUTH_URL, '_blank', 'width=500,height=600');
  const w = window as any;
};

export const requestKakaoAccessToken = (
  code: string,
  loginType: string,
  redirectUri: string,
) => {
  return axios.post('kakao/login', {
    code: code,
    loginType: loginType,
    redirectUri: redirectUri,
  });
};
