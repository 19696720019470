import firebaseApp from 'firebase/app';
import { createContext } from 'react';
import { boolean } from 'zod';

type User = firebaseApp.User;

type RequestLogin = (
  email: string,
  password: string,
) => Promise<boolean | firebaseApp.User | null>;

export interface AuthContextProps {
  currentUser: User | null | undefined;
  requestLogin: RequestLogin | (() => void);
  fetchErrorCode: string;
  handleLogout: () => void;
  requestPassword: (email: string) => void;
  token: string | null;
}

const AuthContext = createContext<AuthContextProps>({
  currentUser: null,
  requestLogin: () => {},
  fetchErrorCode: '',
  handleLogout: () => {},
  requestPassword: () => {},
  token: null,
});

export { AuthContext };
export type { User };
