import produce from 'immer';
import { isArray, isBoolean, isObject, isString } from 'lodash';

import { SidebarInitialDataInterface } from '@/types';
import { VettyGuide } from '@/types';

import { SidebarActionType } from './SidebarActionType';

const sidebarReducer = (
  state: SidebarInitialDataInterface,
  action: {
    type: SidebarActionType;
    payload: unknown;
  },
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_SIDEBAR_ACTIVE': {
        if (isString(action.payload)) {
          const targetHref = action.payload;
          draft.sidebarList = state.sidebarList.map((navItem) => {
            if (targetHref === navItem.href) {
              return {
                ...navItem,
                isActive: true,
              };
            }
            return {
              ...navItem,
              isActive: false,
            };
          });
        }
        break;
      }
      case 'SET_CALL_PHONE_NUMBER': {
        if (isString(action.payload)) {
          draft.callPhoneNumber = action.payload;
        }
        break;
      }
      case 'SET_CALL_ID': {
        if (isString(action.payload)) {
          draft.callId = action.payload;
        }
        break;
      }
      case 'SET_CALLED_TIME': {
        if (typeof action.payload === 'number') {
          draft.calledTime = action.payload;
        }
        break;
      }
      case 'SET_IS_SHOWN_CALL_POP_UP': {
        if (isBoolean(action.payload)) {
          draft.isShownCallPopup = action.payload;
        }
        break;
      }
      case 'SET_LOADING_TEXT': {
        if (isString(action.payload)) {
          draft.loadingText = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_KEY': {
        if (isString(action.payload)) {
          draft.hospitalKey = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_NAME': {
        if (isString(action.payload)) {
          draft.hospitalName = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_ADDRESS': {
        if (isString(action.payload)) {
          draft.hospitalAddress = action.payload;
        }
        break;
      }
      case 'SET_USER_INFO': {
        if (isObject(action.payload)) {
          draft.userInfo = action.payload;
        }
        break;
      }
      case 'SET_USER_NAME': {
        if (isString(action.payload)) {
          draft.userName = action.payload;
        }
        break;
      }
      case 'SET_IS_SHOWN_DATE_PICKER': {
        if (isBoolean(action.payload)) {
          draft.isShownDatePicker = action.payload;
        }
        break;
      }
      case 'SET_DATE_PICKER_USAGE': {
        if (isString(action.payload)) {
          draft.datePickerUsage = action.payload;
        }
        break;
      }
      case 'SET_PREVIOUS_URL': {
        if (isString(action.payload)) {
          draft.previousUrl = action.payload;
        }
        break;
      }
      case 'SET_IS_SHOWN_SIDEBAR': {
        if (isBoolean(action.payload)) {
          draft.isShownSidebar = action.payload;
        }
        break;
      }
      case 'SET_IS_SHOWN_TUTORIAL': {
        if (isBoolean(action.payload)) {
          draft.isShownTutorial = action.payload;
        }
        break;
      }
      case 'SET_NOTICE': {
        if (isArray(action.payload)) {
          draft.notice = action.payload;
        }
        break;
      }
      case 'SET_IS_SHOWN_BLUR': {
        if (isBoolean(action.payload)) {
          draft.isShownBlur = action.payload;
        }
        break;
      }
      case 'SET_IS_LOADED': {
        if (isBoolean(action.payload)) {
          draft.isLoaded = action.payload;
        }
        break;
      }
      case 'SET_IS_LONG_LOADED': {
        if (isBoolean(action.payload)) {
          draft.isLongLoaded = action.payload;
        }
        break;
      }
      case 'SET_IS_ACTIVE_VETTY_QUICK_MENU': {
        if (isBoolean(action.payload)) {
          draft.isActiveVettyQuickMenu = action.payload;
        }
        break;
      }
      case 'SET_VETTY_GUIDE': {
        if (isObject(action.payload)) {
          draft.vettyGuide = action.payload as VettyGuide;
        } else {
          draft.vettyGuide = undefined;
        }
        break;
      }
      case 'SET_IS_SHOWN_VETTY_GUIDE': {
        if (isBoolean(action.payload)) {
          draft.isShownVettyGuide = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_PHONE_NUMBER': {
        if (isString(action.payload)) {
          draft.hospitalPhoneNumber = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_VIRTUAL_NUMBER': {
        if (isString(action.payload)) {
          draft.hospitalVirtualNumber = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_MANAGER_PHONE_NUMBER': {
        if (isString(action.payload)) {
          draft.hospitalManagerPhoneNumber = action.payload;
        }
        break;
      }
      case 'SET_SECOND_HOSPITAL_MANAGER_PHONE_NUMBER': {
        if (isString(action.payload)) {
          draft.secondHospitalManagerPhoneNumber = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_OPENING_HOURS_INFO': {
        if (isObject(action.payload)) {
          draft.hospitalOpeningHoursInfo = action.payload;
        }
        break;
      }
      case 'SET_IS_SHOWN_MULTIPLE_MESSAGE_MODAL': {
        if (isBoolean(action.payload)) {
          draft.isShownMultipleMessageModal = action.payload;
        }
        break;
      }
      case 'SET_SHOWN_RESERVATION_MODAL': {
        if (isObject(action.payload)) {
          draft.isShownReservationModal = action.payload;
        }
        break;
      }
      case 'SET_SHOWN_CREATE_QNA_SLIDE_PANEL': {
        if (isObject(action.payload)) {
          draft.isShownCreateQnaSlidePanel = action.payload;
        }
        break;
      }
      case 'SET_WORK_SHARE_SLIDE_PANEL': {
        if (isObject(action.payload)) {
          draft.workShareSlidePanel = action.payload;
        }
        break;
      }
      case 'SET_FULL_TIMED': {
        if (isBoolean(action.payload)) {
          draft.fullTimed = action.payload;
        }
        break;
      }
      case 'SET_OPEN_ON_PUBLIC_HOLIDAY': {
        if (isBoolean(action.payload)) {
          draft.openOnPublicHoliday = action.payload;
        }
        break;
      }
      case 'SET_SHOWN_FOLLOWUP_MODAL': {
        if (isBoolean(action.payload)) {
          draft.isShownFollowUpModal = action.payload;
        }
        break;
      }
      case 'SET_CHAT_BOT_FULL_TIME_ACTIVE': {
        if (isBoolean(action.payload)) {
          draft.chatBotFullTimeActive = action.payload;
        }
        break;
      }
      case 'SET_MULTIPLE_MESSAGE_SEND_CHAT_INFOS': {
        if (isArray(action.payload) || action.payload === null) {
          draft.multipleMessageSendChatInfos = action.payload;
        }
        break;
      }
      case 'SET_CHAT_BOT_ACTIVE_HOURS_INFO': {
        if (isObject(action.payload)) {
          draft.chatBotActiveHoursInfo = action.payload;
        }
        break;
      }
      case 'SET_ACTIVE_CHAT_BOT_ON_PUBLIC_HOLIDAY': {
        if (isBoolean(action.payload)) {
          draft.activeChatBotOnPublicHoliday = action.payload;
        }
        break;
      }
      case 'SET_MOVE_SELECTED_DATE': {
        if (isString(action.payload)) {
          draft.moveSelectedDate = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_CALL_TTS_AT_WORK': {
        if (isObject(action.payload)) {
          draft.hospitalCallTTSAtWork = action.payload;
        }
        break;
      }
      case 'SET_TAG_LIST': {
        if (isArray(action.payload)) {
          draft.tagList = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_CALL_TTS_AT_BREAK': {
        if (isObject(action.payload)) {
          draft.hospitalCallTTSAtBreak = action.payload;
        }
        break;
      }
      case 'SET_IS_ACTIVE_TTS': {
        if (isBoolean(action.payload)) {
          draft.isActiveTTS = action.payload;
        }
        break;
      }
      case 'SET_IS_SHOWN_PATCH_NOTE': {
        if (isBoolean(action.payload)) {
          draft.isShownPatchNote = action.payload;
        }
        break;
      }
      case 'SET_CURRENT_TTS': {
        if (isString(action.payload)) {
          draft.currentTTS = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_CTI_STATUS': {
        if (isString(action.payload)) {
          draft.hospitalCTIStatus = action.payload;
        }
        break;
      }
      default:
        break;
    }
  });
};

export { sidebarReducer };
