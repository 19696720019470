// 불편성 관리 해주는 라이브러리
// Produce() 함수
import produce from 'immer';
import { isArray, isBoolean, isNumber, isObject, isString } from 'lodash';
import _ from 'lodash';

import { MessageTemplateInitialDataInterface } from '@/types';

import { MessageActionType } from './MessageTemplateActionType';

const MessageReducer = (
  state: MessageTemplateInitialDataInterface,
  action: {
    type: MessageActionType;
    payload: unknown;
  },
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_TEXT_AREA_FOCUS': {
        if (isBoolean(action.payload)) draft.textAreaFocus = action.payload;
        break;
      }
      case 'SET_AUTO_MESSAGE_TEMPLATE': {
        if (isArray(action.payload))
          draft.autoMessageTemplateList = action.payload;
        break;
      }
      case 'SET_QUICK_MESSAGE_TEMPLATE': {
        if (isArray(action.payload))
          draft.quickMessageTemplateList = action.payload;
        break;
      }
      case 'SET_CONTENTS_MESSAGE_TEMPLATE': {
        if (isArray(action.payload))
          draft.contentsMessageTemplateList = action.payload;
        break;
      }
      case 'SET_DEFAULT_AUTO_MESSAGE_TEMPLATE': {
        if (isArray(action.payload))
          draft.defaultAutoMessageTemplateList = action.payload;
        break;
      }
      case 'SET_DEFAULT_QUICK_MESSAGE_TEMPLATE': {
        if (isArray(action.payload))
          draft.defaultQuickMessageTemplateList = action.payload;
        break;
      }
      case 'SET_DEFAULT_CONTENTS_MESSAGE_TEMPLATE': {
        if (isArray(action.payload))
          draft.defaultContentsMessageTemplateList = action.payload;
        break;
      }
      case 'SET_DELETE_ALERT_MESSAGE': {
        if (isString(action.payload)) {
          draft.deleteAlertMessage = action.payload;
        }
        break;
      }
      case 'SET_VISIBLE_AUTO_MESSAGE_TEMPLATE': {
        if (isArray(action.payload))
          draft.visibleAutoMessageTemplateList = action.payload;
        break;
      }
      case 'SET_VISIBLE_QUICK_MESSAGE_TEMPLATE': {
        if (isArray(action.payload))
          draft.visibleQuickMessageTemplateList = action.payload;
        break;
      }
      case 'SET_VISIBLE_CONTENTS_MESSAGE_TEMPLATE': {
        if (isArray(action.payload))
          draft.visibleContentsMessageTemplateList = action.payload;
        break;
      }
      case 'SET_IS_SHOWN_MESSAGE_TEMPLATE_POPUP': {
        if (isBoolean(action.payload)) {
          draft.isShownMessageTemplatePopup = action.payload;
        }
        break;
      }
      case 'SET_IS_SHOWN_ALERT': {
        if (isBoolean(action.payload)) {
          draft.isShownAlert = action.payload;
        }
        break;
      }
      case 'SET_IS_SHOWN_DELETE_ALERT': {
        if (isBoolean(action.payload)) {
          draft.isShownDeleteAlert = action.payload;
        }
        break;
      }
      case 'SET_IS_SHOWN_LOCK_ALERT': {
        if (isBoolean(action.payload)) {
          draft.isShownLockAlert = action.payload;
        }
        break;
      }
      case 'SET_IS_SHOWN_PUBLISH_ALERT': {
        if (isBoolean(action.payload)) {
          draft.isShownPublishAlert = action.payload;
        }
        break;
      }
      case 'SET_SELECTED_MESSAGE_TEMPLATE_KEY': {
        if (isString(action.payload)) {
          draft.selectedMessageTemplateKey = action.payload;
        }
        break;
      }
      case 'SET_KEYWORD_INPUT_TEXT': {
        if (isString(action.payload)) {
          draft.keywordInputText = action.payload;
        }
        break;
      }
      case 'SET_MESSAGE_INPUT_TEXT': {
        if (isString(action.payload)) {
          draft.messageInputText = action.payload;
        }
        break;
      }
      case 'SET_MESSAGE_POPUP_TYPE': {
        if (
          action.payload === '등록' ||
          action.payload === '수정' ||
          action.payload === '삭제' ||
          action.payload === '조회'
        ) {
          draft.messagePopupType = action.payload;
        }
        break;
      }
      case 'SET_QUICK_MESSAGE_TITLE': {
        if (isString(action.payload)) {
          draft.messageTemplatePopupTitle = action.payload;
        }
        break;
      }
    }
  });
};

export { MessageReducer };
