const sidebarList = [
  {
    id: 1,
    menu: '홈',
    href: '/home/',
    isActive: false,
  },
  {
    id: 3,
    menu: '전화 안내',
    href: '/call/',
    isActive: false,
  },
  {
    id: 2,
    menu: '메신저',
    href: '/message/',
    isActive: false,
  },
  // {
  //   id: 3,
  //   menu: '업무 메모장',
  //   href: '/work-share/',
  //   isActive: false,
  // },
  // {
  //   id: 4,
  //   menu: '예약/발송 관리',
  //   href: '/communication/',
  //   isActive: false,
  // },
  {
    id: 5,
    menu: '일정',
    href: '/reservation/',
    isActive: false,
  },
  {
    id: 8,
    menu: '고객 정보',
    href: '/register-user/',
    isActive: false,
  },
  // {
  //   id: 5,
  //   menu: '이전 대화 검색',
  //   href: '/message-history/',
  //   isActive: false,
  // },
  // {
  //   id: 6,
  //   menu: '보호자 등록',
  //   href: '/register-user/',
  //   isActive: false,
  // },
  {
    id: 7,
    menu: '설정',
    href: '/message-template/',
    isActive: false,
  },
  // {
  //   id: 3,
  //   menu: '예약 관리',
  //   href: '/reserve/',
  //   isActive: false,
  // },
];

export { sidebarList };
