import type { LoginInitialDataInterface } from '@/types';

export const LoginInitialData: LoginInitialDataInterface = {
  role: '',
  isConfirmSubscription: true,
  loginFormType: 'signup',
  isAuthCompleted: false,
  vetfluxUserKey: '',
  vetfluxUserName: '',
  email: '',
  password: '',
  phoneNumber: '',
  confirmPassword: '',
  errorMessage: '',
  hospitalKey: '',
  hospitalName: '',
  token: '',
};
