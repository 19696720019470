import { SidebarInitialDataInterface } from '@/types';

import { sidebarList as sidebars } from './sidebarList';

const SidebarInitialData: SidebarInitialDataInterface = {
  calledTime: 0,
  callId: '',
  callPhoneNumber: '',
  isShownCallPopup: false,
  multipleMessageSendChatInfos: [],
  activeChatBotOnPublicHoliday: false,
  chatBotActiveHoursInfo: undefined,
  chatBotFullTimeActive: false,
  fullTimed: false,
  openOnPublicHoliday: false,
  vettyGuide: undefined,
  isShownVettyGuide: false,
  isLoaded: true,
  isLongLoaded: true,
  loadingText: '',
  sidebarList: sidebars,
  hospitalName: '',
  hospitalKey: '',
  userName: '',
  isShownDatePicker: false,
  isShownSidebar: false,
  isShownTutorial: false,
  datePickerUsage: '',
  previousUrl: '',
  userInfo: {},
  notice: [],
  isShownBlur: false,
  isActiveVettyQuickMenu: false,
  hospitalPhoneNumber: '',
  hospitalVirtualNumber: '',
  hospitalManagerPhoneNumber: '',
  secondHospitalManagerPhoneNumber: '',
  hospitalOpeningHoursInfo: undefined,
  isShownReservationModal: {},
  isShownMultipleMessageModal: false,
  isShownFollowUpModal: false,
  isShownCreateQnaSlidePanel: {
    isShown: false,
    type: '조회',
    selectedData: null,
  },
  workShareSlidePanel: {
    isShown: false,
    selectedData: null,
  },
  moveSelectedDate: '',
  isActiveTTS: false,
  hospitalAddress: '',
  tagList: [],
  isShownPatchNote: false,
  currentTTS: '',
};

export { SidebarInitialData };
