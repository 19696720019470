import { createContext, Dispatch } from 'react';

// States Type
import { MessageTemplateInitialDataInterface } from '@/types';

// Action.types Type
import { MessageActionType } from './MessageTemplateActionType';

export const MessageTemplateContext = createContext<
  | {
      state: MessageTemplateInitialDataInterface;
      dispatch: Dispatch<{
        type: MessageActionType;
        payload: unknown;
      }>;
    }
  | undefined
>(undefined);
