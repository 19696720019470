import React, { useEffect, useRef } from 'react';

import VetfluxCloseButton from '../icons/VetfluxCloseButton';

export type durationTypes = 'short' | 'long' | 'infinite';
export interface ToastProps {
  id: string;
  destroy: (id: string) => void;
  title: string;
  content?: string;
  buttonOption?: {
    value: string;
    onClick?: (event: Event) => void;
  };
  loading?: {
    currentProgress: number;
    totalProgress: number;
    status: string;
  };
  duration?: durationTypes;
}

const Toast: React.FC<ToastProps> = (props) => {
  const toastRef = useRef<HTMLDivElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const {
    destroy,
    content = '',
    title,
    duration = 'infinite',
    id,
    buttonOption,
    loading,
  } = props;
  const handleClickEvent = {
    closeOnly: () => {
      destroy(id);
    },
    closeAfterRun: (event) => {
      setTimeout(() => {
        buttonOption.onClick(event);
      }, 1000);
      destroy(id);
    },
  };
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> = null;
    if (!loading) {
      if (!duration) return console.log('duration을 적을 수 없습니다.');
      let runAfterSeconds: number;
      switch (duration) {
        case 'short':
          runAfterSeconds = 2000;
          break;
        case 'long':
          runAfterSeconds = 5000;
          break;
      }
      if (duration !== 'infinite') {
        timer = setTimeout(() => {
          destroy(id);
        }, runAfterSeconds);

        if (toastRef.current) {
          toastRef.current.addEventListener('mouseenter', (event) => {
            clearTimeout(timer);
          });
          toastRef.current.addEventListener('mouseleave', (event) => {
            timer = setTimeout(() => {
              destroy(id);
            }, 1000);
          });
        }
      }
    } else {
      if (progressBarRef.current) {
        const percent = (loading.currentProgress / loading.totalProgress) * 100;
        progressBarRef.current.style.width = `${percent}%`;
        if (loading.status !== 'pending') {
          destroy(id);
        }
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [destroy, duration, id, toastRef, progressBarRef, loading]);

  return (
    <>
      {!loading ? (
        <div ref={toastRef} id={id} className='notiStyle'>
          <div className='messageBox cursor-default'>
            <span
              role='img'
              aria-label='Title'
              className='block text-lg font-bold text-neutral-dark'
            >
              {title}
            </span>
            {content && (
              <p className='text-[16px] pt-[20px] text-[#4d4f5c] leading-5'>
                {content}
              </p>
            )}
          </div>
          <div className='relative '>
            {buttonOption && (
              <button
                onClick={handleClickEvent.closeAfterRun}
                className='flex justify-center items-center h-full px-5 border-l border-[#ececf2] w-[8.75rem] text-[#6c5ce7]'
              >
                <p>{buttonOption.value}</p>
              </button>
            )}
            <button
              className='absolute z-[9999] top-[-10px] right-[-10px]'
              onClick={handleClickEvent.closeOnly}
            >
              <VetfluxCloseButton color={'#b0aebc'} />
            </button>
          </div>
        </div>
      ) : (
        <div id={id} className='notiStyle'>
          <div className='messageBox cursor-default'>
            <span
              role='img'
              aria-label='Title'
              className='block text-[20px] font-bold text-[#4d4f5c] pb-[1.375rem]'
            >
              고객 리스트 업로드중 ({loading.currentProgress} /{' '}
              {loading.totalProgress}) ... 😊
            </span>
            <div className='w-full h-[10px] rounded-[50px] bg-[#ecf2ff] overflow-hidden'>
              <div
                ref={progressBarRef}
                className='w-4 h-[10px] rounded-[50px] bg-[#6c5ce7]'
              >
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const shouldRerender = (prevProps: ToastProps, nextProps: ToastProps) => {
  if (!prevProps.loading) {
    return prevProps.id === nextProps.id;
  } else {
    return false;
  }
};

export default React.memo(Toast, shouldRerender);
