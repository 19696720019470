import React from 'react';

const VetfluxCloseButton = ({ color = '#4d4f5c', width = 30, height = 30 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='📍Trailing Icon'
      width={width}
      height={height}
      viewBox='0 0 30 30'
      // viewBox={'0 0 ' + { width } + ' ' + { height }}
    >
      <path style={{ fill: 'none' }} d='M0 0h30v30H0z' />
      <path
        data-name='↳Color'
        d='M13 26A13 13 0 0 1 3.8 3.8a13.005 13.005 0 0 1 18.4 18.4A12.918 12.918 0 0 1 13 26zm0-11.167 4.667 4.667 1.833-1.833L14.833 13 19.5 8.333 17.667 6.5 13 11.167 8.333 6.5 6.5 8.333 11.167 13 6.5 17.667 8.333 19.5 13 14.834z'
        transform='translate(2 2)'
        style={{ fill: color }}
      />
    </svg>
  );
};

export default VetfluxCloseButton;
