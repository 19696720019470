import * as amplitude from '@amplitude/analytics-browser';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const GA_ID = process.env.GA_ID || '';
export const GTM_ID = process.env.GTM_ID || '';
export const AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY || '';

const isProduction = process.env.BUILD_ENV === 'production';

/* GA (Google Analytics) */
export const gtag = {
  pageview: (url) => {
    if (window && window.gtag) {
      window.gtag('config', isProduction ? GA_ID : '', {
        page_path: url,
      });
    }
  },
  event: ({ action, category, ...properties }) => {
    if (window && window.gtag) {
      window.gtag('event', action, {
        event_category: category,
        event_label: category,
        ...properties,
      });
    }
  },
};

export const useGtag = () => {
  const router = useRouter();

  useEffect(() => {
    if (window && window.gtag) {
      const handleRouteChange = (url: URL) => {
        gtag.pageview(url);
      };

      router.events.on('routeChangeComplete', handleRouteChange);
      router.events.on('hashChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
        router.events.off('hashChangeComplete', handleRouteChange);
      };
    }
  }, [router.events]);
};

/* GTM (Google Tag Manager) */
export const gtm = {
  pageview: (url) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'pageview',
        page: url,
      });
    }
  },
};

export const useGtm = () => {
  const router = useRouter();

  useEffect(() => {
    if (window.dataLayer) {
      router.events.on('routeChangeComplete', () => gtm.pageview);
      return () => {
        router.events.off('routeChangeComplete', gtm.pageview);
      };
    }
  }, [router.events]);
};

/* Amplitude */
export const useAmplitude = () => {
  useEffect(() => {
    amplitude.init(isProduction ? AMPLITUDE_API_KEY : '', undefined, {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: false,
        fileDownloads: true,
      },
      logLevel: isProduction
        ? amplitude.Types.LogLevel.Warn
        : amplitude.Types.LogLevel.None,
    });
  }, []);
};
