import React, { useContext, useEffect, useState } from 'react';

import Icon from '@/context/global/Icon';
import { SidebarContext } from '@/context/global/sidebarContext';
import { sidebarList } from '@/context/global/sidebarList';
import RegisterModal from '@/organisms/global/modal/RegisterModal';
import QuickMenuVetty from '@/organisms/global/QuickMenuVetty';

let timer;
const DemoTemplates = () => {
  const {
    state: { vettyGuide },
    dispatch: globalDispatch,
  } = useContext(SidebarContext);
  const [selectedMenu, setSelectedMenu] = useState('홈');
  const [isShownQuickMenu, setShownQuickMenu] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const pushVettyMessage = (message, action?, buttonName?) => {
    console.log(message, action);
    const button = buttonName ? buttonName : '다음';
    if (action) {
      globalDispatch({
        type: 'SET_VETTY_GUIDE',
        payload: {
          text: message,
          duration: 'infinite',
          actionList: [
            {
              text: button,
              duration: 'infinite',
              onClickEvent: action,
            },
          ],
        },
      });
    } else {
      globalDispatch({
        type: 'SET_VETTY_GUIDE',
        payload: {
          text: message,
          duration: 'infinite',
        },
      });
    }
  };

  useEffect(() => {
    globalDispatch({
      type: 'SET_IS_SHOWN_VETTY_GUIDE',
      payload: true,
    });
    if (selectedMenu === '홈') {
      pushVettyMessage(
        '안녕하세요! 저는 챗봇 베티예요!<br/>지금부터 늘펫 서비스를 소개해 드릴게요.',
        () =>
          pushVettyMessage(
            '늘펫은 동물병원이 보호자와<br/>쉽게 소통할 수 있는 메신저예요.',
            () =>
              pushVettyMessage(
                '내원 안내, 문진 접수, 예약 관리 등이 가능하고<br/> 영업 외 시간에 보호자 대응도 할 수 있어요.',
                () => {
                  pushVettyMessage(
                    '베티가 학습한 150만 건의 상담 데이터와<br/>150개 이상의 수의학 콘텐츠를 기반으로<br/>보호자의 요청사항에 대해 **자동**으로 답변해요.',
                    () => {
                      pushVettyMessage(
                        '지금 보고 계신 화면은 홈 화면이에요.<br/>동물병원에서 보호자에게 메시지를 보낼 때 사용합니다.',
                        () => {
                          pushVettyMessage(
                            '메시지를 보내기 위해서 **챗봇 시작하기**를 눌러볼게요.',
                            () => {
                              setPageIndex(2);
                              pushVettyMessage(
                                '보호자의 **휴대폰 번호 뒤 4자리**나<br/>우리 병원 **환자의 이름**을 입력하시면 돼요.',
                                () => {
                                  setPageIndex(3);
                                  pushVettyMessage(
                                    '베티가 환자 **애디**를 검색해볼게요',
                                    () => {
                                      setPageIndex(4);
                                      pushVettyMessage(
                                        '검색된 결과를 클릭하거나',
                                        () => {
                                          setPageIndex(5);
                                          pushVettyMessage(
                                            '정보가 없다면 **반려동물 추가**를 통해 정보를 만든 다음 보호자에게 메시지를 보낼 수 있어요.',
                                            () => {
                                              setPageIndex(6);
                                              pushVettyMessage(
                                                '보호자에게 첫 메시지를 보내면 우리 병원만의 메신저 채널이 생성됩니다.',
                                                () => {
                                                  setPageIndex(7);
                                                  pushVettyMessage(
                                                    '이제부터는 자유롭게 보호자에게 **팔로업** 메시지를 발송하거나 **다음 예약**을 잡을 수도 있어요.',
                                                    () => {
                                                      pushVettyMessage(
                                                        '전화 안내 페이지도 구경해보세요.',
                                                        () =>
                                                          setSelectedMenu(
                                                            '전화 안내',
                                                          ),
                                                      );
                                                    },
                                                  );
                                                },
                                              );
                                            },
                                          );
                                        },
                                      );
                                    },
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              ),
          ),
      );
    } else if (selectedMenu === '전화 안내') {
      pushVettyMessage(
        '전화 업무를 도와주는<br/>늘펫 전화 연결 서비스도 있습니다.',
        () => {
          setPageIndex(2);
          pushVettyMessage(
            '보호자가 병원에 전화하면 원장님이 설정한 음성 안내 멘트가 나옵니다.',
            () => {
              setPageIndex(3);
              pushVettyMessage(
                '수술 중, 진료 중, 점심 시간 등 동물병원 상황이 보호자에게 자동으로 안내됩니다.',
                () => {
                  setPageIndex(4);
                  pushVettyMessage(
                    '못 받은 전화는 베티가 보낸 늘펫 메시지에서 보호자가 전화한 용건을 확인할 수 있습니다.',
                    () => {
                      pushVettyMessage(
                        '동물병원에 걸려온 전화 목록은 전화 내역에서 한번에 확인할 수 있습니다.',
                        () => {
                          setSelectedMenu('메신저');
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    } else if (selectedMenu === '메신저') {
      pushVettyMessage(
        '메신저에서는 대화 카드를 검색해서 보호자와의 대화 내용을 볼 수 있어요.',
        () => {
          pushVettyMessage(
            '대화창 옆에는 보호자에게는 보이지 않는 메모창이 있어서 특이사항을 기록할 수 있어요.',
            () => {
              setPageIndex(2);
              globalDispatch({
                type: 'SET_IS_SHOWN_VETTY_GUIDE',
                payload: true,
              });
              pushVettyMessage(
                '**메신저**에서는 보호자와 대화하고 챗봇의 동작을 확인할 수 있어요. 왼쪽 위의 검색창에 **전화번호 뒷자리, 반려동물 이름, 보호자 이름, 태그**로 대화 상대를 검색하여 지정할 수 있어요.',
                () => {
                  setPageIndex(3);
                  pushVettyMessage(
                    '메시지는 텍스트 뿐만 아니라 늘펫이 만든 **수의학 콘텐츠, 사진, 동영상, 기타 파일, 문진** 등 원장님의 필요에 따라 다양한 방식으로 보호자와 소통해요.',
                    () => {
                      setPageIndex(4);
                      pushVettyMessage(
                        '특히 수의학 콘텐츠에는 보호자가 자주 물어보는 궁금증에 대한 **의학 자료**가 있어서 원장님이 매번 똑같은 대답을 해야하는 수고를 덜어드려요.',
                        () => {
                          setPageIndex(5);
                          pushVettyMessage(
                            '또한 반복적인 안내들은 간단한 **명령어**만으로 자동 답변이 가능해요.',
                            () => {
                              setPageIndex(6);
                              pushVettyMessage(
                                '영업 외 시간에 보호자가 메시지를 보낼 때도 베티가 대신 답변해 드릴 수 있어요. ',
                                () => {
                                  pushVettyMessage(
                                    '원장님이 일하지 않는 시간에도<br/>보호자에게 **즉시** 설명을 제공해준다는 점 때문에<br/>특히 반응이 좋은 기능이에요.',
                                    () => {
                                      setSelectedMenu('일정');
                                    },
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    } else if (selectedMenu === '일정') {
      pushVettyMessage(
        '보호자와의 메시지를 통해 방문 예약이 잡히면<br/>**일정** 페이지에서 예약 일정을 확인할 수 있어요.',
        () => {
          pushVettyMessage(
            '예약 뿐만 아니라 병원 내 업무 일정이나<br/>원장님의 개인적인 일정도 자유롭게 등록이 가능해요.',
            () => {
              pushVettyMessage(
                '여기저기 분산되어 있던 일정들을<br/>늘펫으로 한번에 관리해보세요.',
                () => {
                  setSelectedMenu('고객 정보');
                },
              );
            },
          );
        },
      );
    } else if (selectedMenu === '설정') {
      pushVettyMessage(
        '**설정**에서는 늘펫 서비스 전반에 관한 설정을 할 수 있어요.',
        () =>
          pushVettyMessage(
            '보호자에게 자동으로 보낼 메시지 내용을<br/>우리 병원의 필요에 맞게 정하거나,<br/>문진 양식을 자유롭게 만드실 수 있어요.',
            () =>
              pushVettyMessage(
                '베티가 안내드릴 내용은 여기까지예요.<br/>혹시 더 궁금하신 점이 있다면,<br/>문의를 통해 언제든지 늘펫팀에게 물어봐주세요.',
                () => {
                  pushVettyMessage(
                    '그럼 베티는 원장님들을 더 도와드릴 방법이 뭐가 있을지 고민하러 가볼게요. <br/>저희 늘펫을 찾아주셔서 정말 감사드려요!',
                    () => {
                      setShownRegisterModal(true);
                    },
                    '도입 신청하기',
                  );
                },
              ),
          ),
      );
    } else if (selectedMenu === '고객 정보') {
      pushVettyMessage(
        '메시지나 일정 외에도<br/>우리 병원 **고객 정보**를 한눈에 모아서 볼 수도 있어요.',
        () => {
          pushVettyMessage(
            '특히 우리 병원이 기존에 사용하던<br/>차트 프로그램의 데이터를 한번에 올리실 수도 있어요!',
            () => {
              setSelectedMenu('설정');
            },
          );
        },
      );
    } else {
      globalDispatch({
        type: 'SET_IS_SHOWN_VETTY_GUIDE',
        payload: false,
      });
    }
    setPageIndex(1);
  }, [selectedMenu]);

  const handleQuickMenu = () => {
    if (!isShownQuickMenu) {
      globalDispatch({
        type: 'SET_IS_SHOWN_VETTY_GUIDE',
        payload: false,
      });
      // setShownGuide(false);
    } else {
      globalDispatch({
        type: 'SET_IS_SHOWN_VETTY_GUIDE',
        payload: true,
      });
    }
    setShownQuickMenu(!isShownQuickMenu);
  };
  const [isShownRegisterModal, setShownRegisterModal] = useState(false);
  /** 베티 마우스 호버 시 텍스트 가이드 띄우기 */
  const onVettyMouseOverEvent = () => {
    if (!isShownQuickMenu && vettyGuide) {
      globalDispatch({
        type: 'SET_IS_SHOWN_VETTY_GUIDE',
        payload: true,
      });
      // setShownGuide(true);
    }
  };

  /** 베티 마우스 아웃 시 시간 후 가이드 닫기 */
  const onVettyMouseOutEvent = () => {
    if (timer) {
      clearTimeout(timer);
    }
    // timer = setTimeout(() => {
    //   globalDispatch({
    //     type: 'SET_IS_SHOWN_VETTY_GUIDE',
    //     payload: false,
    //   });
    // }, 3500);
  };

  return (
    <div className={'w-full h-full flex items-center bg-neutral-dark'}>
      <div className={'flex flex-row w-full px-24 justify-items-stretch'}>
        {isShownRegisterModal && (
          <RegisterModal onCancelClick={() => setShownRegisterModal(false)} />
        )}
        {/*<QuickMenuVetty*/}
        {/*  vettyGuide={vettyGuide}*/}
        {/*  isShownQuickMenu={isShownQuickMenu}*/}
        {/*  handleQuickMenu={handleQuickMenu}*/}
        {/*  vettyMessageStatus={true}*/}
        {/*  onVettyMessageActiveChange={() => {}}*/}
        {/*  onVettyMouseOverEvent={onVettyMouseOverEvent}*/}
        {/*  onVettyMouseOutEvent={onVettyMouseOutEvent}*/}
        {/*  onOpenFollowUpModal={() => {*/}
        {/*    setShownRegisterModal(true);*/}
        {/*  }}*/}
        {/*  onOpenReservationModal={() => {*/}
        {/*    setShownRegisterModal(true);*/}
        {/*  }}*/}
        {/*  onOpenRegisterUserModal={() => {*/}
        {/*    setShownRegisterModal(true);*/}
        {/*  }}*/}
        {/*  handleCopyLinkModal={() => {*/}
        {/*    setShownRegisterModal(true);*/}
        {/*  }}*/}
        {/*  isDemoVersion={true}*/}
        {/*  toolTipSize={'max-w-[26rem]'}*/}
        {/*/>*/}
        <nav
          className={`w-[5rem]  ${
            selectedMenu === '홈' ? 'bg-white' : 'bg-primary'
          }`}
          aria-label='sidebar'
        >
          <div className='h-full space-y-1 overflow-y-hidden'>
            <div className='mb-[1.5rem]'></div>
            {sidebarList &&
              sidebarList.map(({ id, href, menu, isActive }) => {
                return (
                  <button
                    type='button'
                    onClick={() => {
                      setShownQuickMenu(false);
                      setSelectedMenu(menu);
                    }}
                    className={`relative py-[1.5rem] w-full group flex justify-center items-center rounded-md cursor-pointer focus:outline-none max-medium:px-7 ${
                      selectedMenu === '홈'
                        ? menu === selectedMenu
                          ? ' text-primary dark:text-[#ffffff]'
                          : ' text-neutral-light hover:text-primary dark:hover:text-[#ffffff]'
                        : menu === selectedMenu
                        ? ' text-white'
                        : ' text-neutral-light hover:text-white dark:hover:text-[#ffffff]'
                    }`}
                    key={id.toString()}
                    value={href}
                  >
                    <div className={`relative flex items-center`}>
                      <Icon isActive={isActive} href={href} />
                    </div>
                    <div className='flex justify-center items-center fixed left-[11rem] h-[3rem] w-[6rem] opacity-80 bg-primary text-white rounded-3xl invisible group-hover:visible z-40'>
                      {menu}
                    </div>
                  </button>
                );
              })}
          </div>
        </nav>
        <div className='w-full h-full'>
          {selectedMenu === '홈' ? (
            <div className={`relative flex w-full h-full items-end bg-primary`}>
              {/*<img*/}
              {/*  src={'/demo/img_demo_msg.png'}*/}
              {/*  alt={'blur_image'}*/}
              {/*  className={`absolute w-full h-full blur-2xl`}*/}
              {/*  style={{ objectFit: 'cover', objectPosition: 'center' }}*/}
              {/*/>*/}
              <img
                src={`/demo/demo_home${pageIndex}.png`}
                alt={'demo_image'}
                className={`w-full h-full z-10`}
                style={{ objectFit: 'contain', objectPosition: 'center' }}
              />
            </div>
          ) : selectedMenu === '전화 안내' ? (
            <div className={`relative flex w-full h-full items-end`}>
              <img
                src={`/demo/img_demo_call${pageIndex}.png`}
                alt={'demo_image'}
                className={`w-full h-full z-10`}
                style={{ objectFit: 'contain', objectPosition: 'center' }}
              />
            </div>
          ) : selectedMenu === '메신저' ? (
            <div className={`relative flex w-full h-full items-end`}>
              {pageIndex === 1 ? (
                <img
                  src={'/demo/img_demo_msg.png'}
                  alt={'demo_image'}
                  className={`w-full h-full z-10`}
                  style={{ objectFit: 'contain', objectPosition: 'center' }}
                />
              ) : (
                <img
                  src={`/demo/demo_messenger${pageIndex}.png`}
                  alt={'demo_image'}
                  className={`w-full h-full z-10`}
                  style={{ objectFit: 'contain', objectPosition: 'center' }}
                />
              )}
              {/*<img*/}
              {/*  src={'/demo/img_demo_msg.png'}*/}
              {/*  alt={'blur_image'}*/}
              {/*  className={`absolute w-full h-full blur-2xl`}*/}
              {/*  style={{ objectFit: 'cover', objectPosition: 'center' }}*/}
              {/*/>*/}
            </div>
          ) : selectedMenu === '일정' ? (
            <div className={`relative flex w-full h-full items-end`}>
              <img
                src={'/demo/img_demo_reservation.png'}
                alt={'demo_image'}
                className={`w-full h-full z-10`}
                style={{ objectFit: 'contain', objectPosition: 'center' }}
              />
            </div>
          ) : selectedMenu === '설정' ? (
            <div className={`relative flex w-full h-full items-end`}>
              <img
                src={'/demo/img_demo_message_template.png'}
                alt={'demo_image'}
                className={`w-full h-full z-10`}
                style={{ objectFit: 'contain', objectPosition: 'center' }}
              />
            </div>
          ) : selectedMenu === '고객 정보' ? (
            <div className={`relative flex w-full h-full items-end`}>
              <img
                src={`/demo/demo_register${pageIndex}.png`}
                alt={'demo_image'}
                className={`w-full h-full z-10`}
                style={{ objectFit: 'contain', objectPosition: 'center' }}
              />
            </div>
          ) : (
            <div>기타</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DemoTemplates;
