import { useEffect, useReducer, useRef } from 'react';

import { MessageActionType } from './MessageTemplateActionType';
import { MessageTemplateContext } from './messageTemplateContext';
import { MessageInitialData } from './MessageTemplateInitialData';
import { MessageReducer } from './messageTemplateReducer';

export const MessageTemplateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MessageReducer, MessageInitialData);
  const value = { state, dispatch };
  return (
    <MessageTemplateContext.Provider value={value}>
      {children}
    </MessageTemplateContext.Provider>
  );
};
