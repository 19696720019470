import DemoTemplates from '@/templates/Demo.templates';

const Demo = () => {
  return (
    <div className={'w-full h-full'}>
      <DemoTemplates />
    </div>
  );
};
export default Demo;
