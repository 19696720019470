import React from 'react';

const VetFluxModalCloseButton = ({ onCancelClick, color = '#4D4F5C' }) => {
  return (
    <button
      className='absolute right-8 top-8 cursur-pointer text-neutral-normal z-20'
      onClick={onCancelClick}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M20 1.32074L18.6792 0L10 8.6792L1.32085 0L0 1.32074L8.67921 10L0 18.6793L1.32085 20L10 11.3208L18.6792 20L20 18.6793L11.3208 10L20 1.32074Z'
          fill={color}
        />
      </svg>
    </button>
  );
};

export default VetFluxModalCloseButton;
