import dayjs from 'dayjs';
import React from 'react';

const TopA2HSBar = ({
  installApp,
  deferredPrompt,
  clickTime,
  onClickXButton,
}) => {
  return (
    <a href='#' onClick={installApp}>
      <div
        className={`z-50 fixed w-[100%] h-[40px] bg-primary-700 flex justify-center items-center transition-all ease-in-out duration-700 ${
          deferredPrompt && !dayjs(clickTime).isToday()
            ? 'top-0 bar-shadow '
            : 'top-[-50px]'
        }`}
      >
        <p className='text-white text-sm'>
          <span
            className='underline font-semibold'
            style={{ textUnderlineOffset: 4 }}
          >
            여기를
          </span>{' '}
          눌러 바탕화면에 늘펫을 설치해주세요.
        </p>
        <button
          onClick={onClickXButton}
          className='absolute right-6 text-white'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M20 1.32074L18.6792 0L10 8.6792L1.32085 0L0 1.32074L8.67921 10L0 18.6793L1.32085 20L10 11.3208L18.6792 20L20 18.6793L11.3208 10L20 1.32074Z'
              fill='white'
            />
          </svg>
        </button>
      </div>
    </a>
  );
};

export default TopA2HSBar;
