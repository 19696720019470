import * as Sentry from '@sentry/nextjs';
import { captureException } from '@sentry/nextjs';
import axios, { AxiosError, AxiosResponse } from 'axios';
import firebase from 'firebase';

import { toast } from '@/atoms/toastify/ToastManager';

export const axiosDefaultSettings = () => {
  return {
    // baseURL: process.env.DEV_URL as string,
    BASE_URL: process.env.BASE_URL as string,
    DEV_URL: process.env.DEV_URL as string,
    NGROK_URL: 'https://a177-124-111-156-86.ngrok.io/' as string,
    timeout: 10000,
  };
};

export const axiosGetToken = (setTokenDispatch: (token: string) => void) => {
  firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(function (idToken) {
      axios.defaults.headers.common['Authorization'] = idToken;
      setTokenDispatch(idToken);
    })
    .catch(function (error) {
      // Handle error
      captureException(error);
    });
};

export const axiosInterceptorsTokenRefresh = (
  setTokenDispatch: (token: string) => void,
) => {
  let refreshAttempts = 0;

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError) => {
      const {
        config,
        response: { status },
      } = error;
      Sentry.captureException(new Error(`sentry error capture : ${error}`));
      if (
        (error.response.status === 401 || error.response.status === 403) &&
        refreshAttempts < 3
      ) {
        refreshAttempts++;
        const originalRequest = config;
        await firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            refreshAttempts = 0;
            axios.defaults.headers.common['Authorization'] = idToken;
            originalRequest.headers.Authorization = idToken;
            setTokenDispatch(idToken);
          })
          .catch(function (error) {
            captureException('인증 토큰 갱신 실패', error);
            toast.show({
              title: `인증 토큰 갱신.\n${error}`,
            });
          });
        return axios(originalRequest);
      }
      return Promise.reject(error);
    },
  );
};
