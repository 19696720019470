import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import '@/styles/tailwind.base.css';
import '@/styles/toast.scss';
import '@/styles/global.scss';
import '@/styles/loadingAnimation.scss';
import 'react-toastify/dist/ReactToastify.css';

import { FuegoProvider } from '@nandorojo/swr-firestore';
import { axiosDefaultSettings } from 'api';
import axios from 'axios';
import TopA2HSBar from 'components/Hoc/TopA2HSBar';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import firebase from 'firebase';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useLocation, useWindowSize } from 'react-use';

import { AuthProvider } from '@/context/auth/AuthProvider';
import SidebarProvider from '@/context/global/SidebarProvider';
import LoginProvider from '@/context/login/LoginProvider';
import { MessageTemplateProvider } from '@/context/messageTemplate/MessageTemplateProvider';
import { SafariIOSInstallGuide } from '@/organisms/mobile/SafariIOSInstallGuide';
import { Fuego } from '@/utils/fuego';
import { useAmplitude, useGtag, useGtm } from '@/utils/tags';
import {
  getMobileOS,
  isInAppBrowser,
  isIOSInstalled,
} from '@/utils/userAgentChecker';

import { useA2HS } from '../hooks/useA2HS';
import Demo from './demo';

const Layout = dynamic(() => import('../components/Hoc/Layout'));
const GlobalOverRay = dynamic(() => import('../components/Hoc/GlobalOverRay'));
const TemplateLayout = dynamic(
  () => import('../components/organisms/TemplateLayout'),
);

const MobilePage = dynamic(() => import('./mobile-message'));
const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databeseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSEGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

const fuego = new Fuego(config);
dayjs().locale('ko');
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Seoul');
const App = ({ Component, pageProps }: AppProps) => {
  useAmplitude();
  useGtag();
  useGtm();
  const [viewport, setViewport] = useState('mobile');
  const [viewportWidth, setViewportWidth] = useState(0);
  const [downloadVisible, setDownloadVisible] = useState(false);
  const location = useLocation();
  const { width } = useWindowSize();
  const router = useRouter();
  const { deferredPrompt, installApp, clearPrompt } = useA2HS();

  const { pathname } = location;
  const pathName = pathname;

  const onClickInstallMobileA2HS = () => {
    installApp();
  };

  useEffect(() => {
    // axios.defaults.baseURL = 'http://localhost:8080/';
    switch (process.env.NODE_ENV) {
      case 'production':
        //BASE_URL
        axios.defaults.baseURL = axiosDefaultSettings().BASE_URL;
        break;
      case 'development':
        //DEV_URL
        axios.defaults.baseURL = axiosDefaultSettings().DEV_URL;
        break;
    }
  }, []);
  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(async (registrations) => {
        if (registrations && registrations.length > 0) {
          registrations.forEach((registration) => {
            registration.update();
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (getMobileOS() === 'iOS' && !isIOSInstalled()) {
      setDownloadVisible(true);
    }
    if (deferredPrompt) {
      setDownloadVisible(true);
    }
  }, [deferredPrompt]);

  //서비스 워커 메시지 수신
  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging.onMessage((onMessage) => {
        if (Notification.permission === 'granted') {
          const value = JSON.parse(onMessage.data.value);
          const today = new Date().getDate();
          const createdDate = new Date(value.createdDate).getDate();
          navigator.serviceWorker
            .getRegistration('/firebase-cloud-messaging-push-scope')
            .then((registration) => {
              registration
                .getNotifications()
                .then((notifications) => {
                  const notificationMap = {};
                  notifications.forEach((notification) => {
                    if (
                      notificationMap[notification.data.channel] === undefined
                    ) {
                      notificationMap[notification.data.channel] = [];
                    }
                    notificationMap[notification.data.channel].push(
                      notification,
                    );
                  });
                  return notificationMap;
                })
                .then((notificationMap) => {
                  const options = {
                    icon: '/web_push_icon.png',
                    body: onMessage.data.message,
                    data: onMessage.data,
                  } as any;
                  let title = '';
                  switch (onMessage.data.channel) {
                    case 'chat':
                      {
                        title = '보호자 메시지가 도착했습니다.';
                        registration
                          .getNotifications()
                          .then((notifications) => {
                            notifications.forEach((notification) => {
                              if (notification.data.channel === 'chat') {
                                notification.close();
                              }
                            });
                          });
                        // const chatNotifications = notificationMap['chat'];
                        // if (chatNotifications && chatNotifications.length > 0) {
                        //   options.body = `읽지 않은 보호자 메시지가 ${chatNotifications.length}개 있습니다.`;
                        // }
                        // if (today !== createdDate) {
                        //   options.tag = createdDate;
                        //   options.renotify = true;
                        //   options.body =
                        //     '읽지 않은 지난 보호자 메시지를 확인해주세요.';
                        // }
                      }
                      break;
                    case 'work': {
                      title = onMessage.data.title;
                      registration.getNotifications().then((notifications) => {
                        notifications.forEach((notification) => {
                          if (notification.data.channel === 'work') {
                            notification.close();
                          }
                        });
                      });
                      break;
                    }
                    case 'visit': {
                      const visitNotifications = notificationMap['visit'];
                      const value = JSON.parse(onMessage.data.value);
                      if (visitNotifications && visitNotifications.length > 0) {
                        options.body = `${value.petInfo.name} 보호자님이 방문했습니다.`;
                      }
                      break;
                    }
                    case 'qna':
                      {
                        title = '보호자 문진이 도착했습니다.';
                        options.image = '/img_noti_qna.png';
                        options.requireInteraction = true;
                        // const qnaNotifications = notificationMap['qna'];
                        // if (qnaNotifications && qnaNotifications.length > 0) {
                        //   options.body = `읽지 않은 보호자 문진이 ${qnaNotifications.length}개 있습니다.`;
                        // }
                      }
                      break;
                    case 'request_reservation':
                      {
                        title = '보호자 예약신청이 도착했습니다.';
                        options.image = '/img_noti_request_reservation.png';
                        options.requireInteraction = true;
                        // const qnaNotifications = notificationMap['request_reservation'];
                        // if (qnaNotifications && qnaNotifications.length > 0) {
                        //   options.body = `읽지 않은 보호자 문진이 ${qnaNotifications.length}개 있습니다.`;
                        // }
                      }
                      break;
                    case 'call': {
                      title = onMessage.data.title;
                      options.image = '/img_noti_call.png';
                      options.requireInteraction = true;
                      registration.getNotifications().then((notifications) => {
                        notifications.forEach((notification) => {
                          if (notification.data.channel === 'call') {
                            notification.close();
                          }
                        });
                      });
                      break;
                    }
                  }
                  // notification.onClick = () => {
                  //
                  // }
                  registration.showNotification(title, options);
                });
            });
        }
      });
    }

    if (screen.width < 1440 && viewport === 'desktop') {
      setViewportWidth(1920);
    }
    if (screen.width >= 1440) {
      setViewportWidth(screen.width);
    }
  }, [width, viewport]);

  // useEffect(() => {
  //   // console.log(width);
  //   // setViewportWidth(width);
  // }, [width]);

  const [isIOS, setIOS] = useState<boolean | undefined>();
  useEffect(() => {
    if (getMobileOS() === 'iOS' && !isIOSInstalled()) {
      setIOS(true);
    } else {
      setIOS(false);
    }
  }, []);

  const [clickTime, setClickTime] = useState(dayjs().valueOf());

  useEffect(() => {
    if (!window) {
      return;
    }
    const storage = window.localStorage;
    const time = Number(storage.getItem('rejectInstallA2HSTime'));
    // setTimeout(() => {
    setClickTime(time);
    // }, 1000);
  }, []);

  const onClickXButton = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.localStorage.setItem(
      'rejectInstallA2HSTime',
      dayjs().valueOf().toString(),
    );
    setClickTime(dayjs().valueOf());
  };
  return (
    <SidebarProvider>
      <FuegoProvider fuego={fuego}>
        {isIOS !== undefined &&
          (isIOS ? (
            typeof window !== 'undefined' && (
              <Layout
                viewport={viewport}
                viewportWidth={viewportWidth}
                width={width}
              >
                <SafariIOSInstallGuide />
              </Layout>
            )
          ) : (
            <LoginProvider>
              {pathName === '/demo/' ? (
                <Demo />
              ) : (
                <AuthProvider>
                  <MessageTemplateProvider>
                    {/* 1. pathName != '/' && window 객체가 undefined 가 아닌 경우  */}
                    {pathName !== '/' && typeof window !== 'undefined' ? (
                      <>
                        <GlobalOverRay />
                        <Layout
                          viewport={viewport}
                          viewportWidth={viewportWidth}
                          width={width}
                        >
                          {/* 1-1.navigator.userAgent 가 /MSIE or rv: IEMobile   */}
                          {navigator.userAgent.match(/MSIE|rv:|IEMobile/i) ? (
                            <div className='text-white bg-primary'>
                              지원하지 않는 브라우저입니다.
                            </div>
                          ) : /* 1-2.navigator.userAgent 가 /MSIE or rv: IEMobile 아닌 경우   */
                          // 1-2-1. pathName == /login/ or /kakao-redirect/ or /kakao-login/ or /authenticating/
                          pathName === '/login/' ||
                            pathName === '/kakao-redirect/' ||
                            pathName === '/kakao-login/' ||
                            pathName === '/authenticating/' ||
                            pathname === '/internal-auth/' ? (
                            <Component {...pageProps} />
                          ) : // 1-2-2. pathName != /login/ && /kakao-redirect/ && /kakao-login/ && /authenticating/
                          // 1-2-2-1. pathName == /mobile-message/
                          pathName === '/mobile-message/' ? (
                            <MobilePage
                              onClickInstallMobileA2HS={
                                onClickInstallMobileA2HS
                              }
                              downloadVisible={downloadVisible}
                            />
                          ) : (
                            // 1-2-2-2. pathName != /mobile-message/
                            // 기본 페이지
                            <>
                              <TopA2HSBar
                                installApp={installApp}
                                deferredPrompt={deferredPrompt}
                                clickTime={clickTime}
                                onClickXButton={onClickXButton}
                              />
                              <TemplateLayout pathName={pathname}>
                                <Component {...pageProps} pathName={pathname} />
                              </TemplateLayout>
                            </>
                          )}
                        </Layout>
                      </>
                    ) : (
                      /* 2. pathName == '/' or window 객체가 undefined 인 경우  */
                      <div className='h-full flex items-center justify-center'>
                        <div className='loader' />
                      </div>
                    )}
                  </MessageTemplateProvider>
                </AuthProvider>
              )}
            </LoginProvider>
          ))}
      </FuegoProvider>
    </SidebarProvider>
  );
};
export default App;
