import { createContext, Dispatch } from 'react';

import { SidebarInitialDataInterface } from '@/types';

import { SidebarActionType } from './SidebarActionType';

export const SidebarContext = createContext<
  | {
      state: SidebarInitialDataInterface;
      dispatch: Dispatch<{ type: SidebarActionType; payload: unknown }>;
    }
  | undefined
>(undefined);
