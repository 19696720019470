import { captureException } from '@sentry/nextjs';

export const doCopyFromNotification = (text, registration) => {
  if (navigator.clipboard) {
    // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
    navigator.clipboard
      .writeText(text)
      .then(() => {
        const options = {
          icon: './web_push_icon.png',
          body: '환자 정보가 클립보드에 복사되었습니다',
        };
        registration.showNotification(
          '환자 정보가 클립보드에 복사 되었습니다.',
          options,
        );
      })
      .catch(() => {
        // toast.show({
        //   title: '복사를 다시 시도해 주세요',
        //   duration: 'short',
        // });
      });
  } else {
    if (!document.queryCommandSupported('copy')) {
      // return toast.show({
      //   title: '복사를 지원하지 않는 브라우저 입니다.',
      //   duration: 'short',
      // });
    }
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }
};
/** 클립보드에 저장 */
export const doCopy = async (text) => {
  if (navigator.clipboard) {
    // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
    return navigator.clipboard
      .writeText(text)
      .then(() => {
        return true;
        // toast.show({
        //   title: '메신저 링크가 클립보드에 복사되었습니다',
        //   content: '보호자에게 늘펫 메신저를 바로 공유해보세요.',
        //   duration: 'short',
        // });
      })
      .catch((error) => {
        captureException(error);
        return false;
        // toast.show({
        //   title: '복사를 다시 시도해 주세요',
        //   duration: 'short',
        // });
      });
  } else {
    if (!document.queryCommandSupported('copy')) {
      // return toast.show({
      //   title: '복사를 지원하지 않는 브라우저 입니다.',
      //   duration: 'short',
      // });
    }
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    return true;
    // toast.show({
    //   title: '메신저 링크가 클립보드에 복사되었습니다',
    //   content: '보호자에게 늘펫 메신저를 바로 공유해보세요.',
    //   duration: 'short',
    // });
  }
};
