import React from 'react';
import { MouseEventHandler } from 'react';

export const VetfluxButton = ({
  className,
  onClick,
  text,
  type,
  disabled = false,
}: {
  className?: string;
  text: string | React.ReactElement<'div'>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type: number;
  disabled?: boolean;
}) => {
  const typeByStyle = (): string => {
    switch (type) {
      case 0:
        return 'text-white bg-primary min-w-[7.5rem] px-6 py-3.5 border border-primary hover:bg-primary-600 hover:border-primary-600';
        break;
      case 1:
        return 'text-primary bg-white min-w-[7.5rem] px-6 py-3.5 border border-primary hover:bg-primary hover:border-primary hover:text-white';
        break;
      case 2:
        return 'bg-[#b0aebc] text-white px-6 py-4 hover:opacity-80';
        break;
      case 3:
        return 'text-neutral-dark py-2 px-3 text-sm bg-secondary-light hover:bg-secondary-dark rounded-lg font-semibold';
        break;
      case 4:
        return 'text-white bg-[#F38181] min-w-[7.5rem] px-6 py-3.5 hover:opacity-80';
        break;
    }
  };
  return (
    <button
      onClick={onClick}
      className={`${className} leading-none transition rounded-md ${typeByStyle()} ${
        disabled
          ? ` bg-[#b0aebc] border-[#b0aebc] hover:bg-[#b0aebc] hover:border-[#b0aebc] hover:cursor-not-allowed`
          : ``
      }`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
