import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/browser';
import { captureException } from '@sentry/nextjs';
import { axiosGetToken, axiosInterceptorsTokenRefresh } from 'api';
import firebase from 'firebase';
import { useRouter } from 'next/router';
import { FC, useContext, useEffect, useState } from 'react';
import { useLocation, useWindowSize } from 'react-use';

import { SidebarContext } from '@/context/global/sidebarContext';
import { UserInfo } from '@/types';

import { LoginContext } from '../login/LoginContext';
import { AuthContext, User } from './authStore';

const useFirebaseAuth = () => {
  const [currentUser, setCurrentUser] = useState<User | null | undefined>(
    firebase.auth().currentUser,
  );
  const [token, setToken] = useState<string | null>(null);

  const [isUserLoad, setUserLoad] = useState(false);
  const [fetchErrorCode, setFetchErrorCode] = useState('');
  const { dispatch } = useContext(SidebarContext);
  const { dispatch: loginDispatch } = useContext(LoginContext);
  const verifiedRout = [
    '/home/',
    '/message/',
    '/call/',
    '/mobile-message/',
    '/reservation/',
    '/message-template/',
    '/register-user/',
    '/authenticating/',
    '/kakao-redirect/',
    '/kakao-login/',
    '/404/',
  ];
  const router = useRouter();

  const { width } = useWindowSize();
  const [isMobilePage, setMobilePage] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent) {
      const isMobile = /Mobile/.test(userAgent);
      setMobilePage(isMobile);
    }
  }, [width]);

  const setTokenStore = (idToken: string) => {
    loginDispatch({
      type: 'SET_TOKEN',
      payload: idToken,
    });
    setToken(idToken);
  };

  const requestPassword = (email: string) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        alert('비밀번호 변경을 위한 메일이 전송되었습니다 : ' + email);
      })
      .catch((e) => {
        console.log(e);
        if (e.code === 'auth/user-not-found') {
          alert('계정이 존재하지 않습니다.');
        } else {
          alert('비밀번호 변경을 위한 메일 전송에 실패했습니다.');
        }
      });
  };
  const requestLogin = (email: string, password: string) => {
    dispatch({
      type: 'SET_IS_LOADED',
      payload: false,
    });
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        const db = firebase.firestore();
        db.collection('VetFluxUser')
          .where('email', '==', response.user.email)
          .get()
          .then((list) => {
            dispatch({
              type: 'SET_IS_LOADED',
              payload: true,
            });
            loginDispatch({
              type: 'SET_PASSWORD',
              payload: '',
            });

            if (list.empty) {
              alert(
                '등록되지 않은 사용자 입니다. 자세한 사항은 원장님 또는 고객센터에 문의해주세요.',
              );
              loginDispatch({
                type: 'SET_VETFLUX_USER_PHONE_NUMBER',
                payload: `0${response.user.phoneNumber.replace('+82', '')}`,
              });
              loginDispatch({
                type: 'SET_LOGIN_FORM_TYPE',
                payload: 'introductory',
              });
            }
          })
          .catch((error) => {
            alert('조회 실패');
            alert(
              '로그인이 실패했습니다. 잠시 후, 아이디와 비밀번호를 다시 확인 한다음 작성 주세요.',
            );
            dispatch({
              type: 'SET_IS_LOADED',
              payload: true,
            });
          });
        return false;
      })
      .catch(function (error) {
        // Handle Errors here.
        if (error.code === 'auth/too-many-requests') {
          alert(
            '해당 아이디로 로그인시도가 너무 많습니다. 2분 뒤 다시 시도해주세요.',
          );
          setFetchErrorCode(
            '해당 아이디로 로그인시도가 너무 많습니다. 2분 뒤 다시 시도해주세요.',
          );
          dispatch({
            type: 'SET_IS_LOADED',
            payload: true,
          });
          setTimeout(() => {
            setFetchErrorCode('');
          }, 2000);
        } else if (error.code === 'auth/user-not-found') {
          alert('가입된 계정이 아닙니다. 이메일 주소를 다시 확인해 주세요.');
          dispatch({
            type: 'SET_IS_LOADED',
            payload: true,
          });
        } else if (error.code === 'auth/invalid-email') {
          alert(
            '이메일 주소 형식이 아닙니다. 이메일 주소를 다시 확인해 주세요.',
          );
          dispatch({
            type: 'SET_IS_LOADED',
            payload: true,
          });
        } else if (error.code === 'auth/wrong-password') {
          console.log('error', error);
          alert(
            '비밀번호가 다릅니다. 비밀번호를 잊으셨다면 아래 비밀번호 찾기를 눌러 진행해주세요.',
          );
          dispatch({
            type: 'SET_IS_LOADED',
            payload: true,
          });
        } else {
          alert(error.message);
          dispatch({
            type: 'SET_IS_LOADED',
            payload: true,
          });
        }
        return false;
      });
  };
  const handleLogout = async () => {
    amplitude.track('Log Out');
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();

      console.log('registrations', registrations);
      for (const registration of registrations) {
        console.log('nice', registration);
        registration.unregister();
      }
    }
    return firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.setItem('welcomePopup', 'false');
        setCurrentUser(null);
        router.replace('/login/');
      });
  };
  const state = useLocation();

  useEffect(() => {
    let userSnapshot;
    let userRef;
    if (state.pathname.includes('internal-auth')) return;
    if (!isUserLoad) {
      return;
    }
    if (currentUser) {
      const db = firebase.firestore();
      axiosGetToken(setTokenStore);
      axiosInterceptorsTokenRefresh(setTokenStore);
      if (currentUser.phoneNumber) {
        const phoneNumber = currentUser.phoneNumber.includes('+82')
          ? currentUser.phoneNumber.replace('+82', '0')
          : currentUser.phoneNumber;
        userRef = db
          .collection('VetFluxUser')
          .where('phoneNumber', '==', phoneNumber);
      } else {
        userRef = db
          .collection('VetFluxUser')
          .where('email', '==', currentUser.email);
      }
      userSnapshot = userRef.onSnapshot(
        (list) => {
          if (list.empty) {
            console.log('currentUser', currentUser);
            loginDispatch({
              type: 'SET_VETFLUX_USER_PHONE_NUMBER',
              payload: `0${currentUser.phoneNumber.replace('+82', '')}`,
            });
            loginDispatch({
              type: 'SET_LOGIN_FORM_TYPE',
              payload: 'introductory',
            });
            loginDispatch({
              type: 'SET_VETFLUX_USER_NAME',
              payload: currentUser.displayName,
            });
            loginDispatch({
              type: 'SET_EMAIL',
              payload: currentUser.email,
            });
            router.replace('/login');
          } else {
            list.forEach((doc) => {
              if (doc.exists) {
                const data = doc.data() as UserInfo;
                Sentry.setUser({
                  id: data.key,
                  username: `${data.hospitalName} ${data.name}`,
                  email: data.email,
                });
                console.log(data);

                if (data) {
                  amplitude.setUserId(data.key);
                  const identifyEvent = new amplitude.Identify();
                  identifyEvent.set('userName', data.name);
                  identifyEvent.set('userEmail', data.email);
                  identifyEvent.set('userPhone', data.phoneNumber);
                  identifyEvent.set('userHospitalName', data.hospitalName);
                  amplitude.identify(identifyEvent);

                  if (!data.hospitalKey) {
                    console.log('no hospitalKey');
                    loginDispatch({
                      type: 'SET_HOSPITAL_NAME',
                      payload: data.hospitalName,
                    });
                    loginDispatch({
                      type: 'SET_VETFLUX_USER_KEY',
                      payload: data.key,
                    });
                    loginDispatch({
                      type: 'SET_VETFLUX_USER_NAME',
                      payload: data.name,
                    });
                    loginDispatch({
                      type: 'SET_VETFLUX_USER_PHONE_NUMBER',
                      payload: data.phoneNumber,
                    });
                    loginDispatch({
                      type: 'SET_LOGIN_FORM_TYPE',
                      payload: 'introductory',
                    });
                    loginDispatch({
                      type: 'SET_AUTH_COMPLETED',
                      payload: true,
                    });
                    loginDispatch({
                      type: 'SET_IS_CONFIRM_SUBSCRIPTION',
                      payload: false,
                    });
                    if (data.role === 'authenticating') {
                      router.replace('/authenticating');
                    } else {
                      router.replace('/login');
                    }
                  } else {
                    if (data.approve === false) {
                      loginDispatch({
                        type: 'SET_VETFLUX_USER_KEY',
                        payload: data.key,
                      });
                      loginDispatch({
                        type: 'SET_VETFLUX_USER_NAME',
                        payload: data.name,
                      });
                      loginDispatch({
                        type: 'SET_VETFLUX_USER_PHONE_NUMBER',
                        payload: data.phoneNumber,
                      });
                      loginDispatch({
                        type: 'SET_AUTH_COMPLETED',
                        payload: true,
                      });
                      loginDispatch({
                        type: 'SET_IS_CONFIRM_SUBSCRIPTION',
                        payload: true,
                      });
                      loginDispatch({
                        type: 'SET_HOSPITAL_KEY',
                        payload: data.hospitalKey,
                      });
                      loginDispatch({
                        type: 'SET_HOSPITAL_NAME',
                        payload: data.hospitalName,
                      });
                      loginDispatch({
                        type: 'SET_LOGIN_FORM_TYPE',
                        payload: 'signup',
                      });
                      if (data.role === 'authenticating') {
                        router.replace('/authenticating');
                      } else {
                        router.replace('/login/');
                      }
                      return;
                    }
                    dispatch({
                      type: 'SET_HOSPITAL_NAME',
                      payload: data.hospitalName,
                    });
                    dispatch({
                      type: 'SET_HOSPITAL_KEY',
                      payload: data.hospitalKey,
                    });
                    dispatch({
                      type: 'SET_USER_NAME',
                      payload: data.name,
                    });
                    dispatch({
                      type: 'SET_USER_INFO',
                      payload: data,
                    });
                    loginDispatch({
                      type: 'SET_HOSPITAL_KEY',
                      payload: data.hospitalKey,
                    });
                    loginDispatch({
                      type: 'SET_HOSPITAL_NAME',
                      payload: data.hospitalName,
                    });
                    loginDispatch({
                      type: 'SET_VETFLUX_USER_KEY',
                      payload: data.key,
                    });
                    loginDispatch({
                      type: 'SET_VETFLUX_USER_ROLE',
                      payload: data.role,
                    });
                    loginDispatch({
                      type: 'SET_VETFLUX_USER_PHONE_NUMBER',
                      payload: data.phoneNumber,
                    });
                    loginDispatch({
                      type: 'SET_AUTH_COMPLETED',
                      payload: true,
                    });
                    loginDispatch({
                      type: 'SET_VETFLUX_USER_NAME',
                      payload: data.name,
                    });

                    const currentPath = state.pathname;
                    const currentHref = state.href;
                    // 어센티케이팅일때, PC는 어센, 모바일은 모바일로 이동 (예외 처리)
                    if (data.role === 'authenticating' && !isMobilePage) {
                      if (isMobilePage) {
                        router.replace(`/mobile-message/`);
                      } else {
                        router.replace('/authenticating');
                      }
                    } else if (currentPath.indexOf('login') !== -1) {
                      if (isMobilePage) {
                        router.replace('/mobile-message/');
                        return;
                      } else {
                        router.replace('/home/');
                      }
                    } else {
                      if (isMobilePage) {
                        if (router.query?.key) {
                          router.replace(
                            `/mobile-message/?key=${router.query.key}`,
                          );
                        } else {
                          router.replace(`/mobile-message/`);
                        }
                        return;
                      }

                      // /는 홈으로 이동, 이외의 url을 검사하여 유효한 url 배열에 없으면 404로 이동, kakao-redirect일 땐 홈으로 이동
                      if (currentPath === '/') {
                        router.replace('/home/');
                      } else {
                        if (verifiedRout.includes(currentPath)) {
                          console.log('currentHref', currentHref);
                          if (currentHref.includes('kakao-redirect')) {
                            router.replace('/home');
                          } else if (currentHref.includes('authenticating')) {
                            router.replace('/home');
                          } else {
                            // router.replace(currentHref);
                          }
                        } else {
                          router.replace('/404/');
                        }
                      }
                    }
                  }
                }
              }
            });
          }
        },
        (error) => {
          captureException(error);
          setCurrentUser(null);
        },
      );
    } else {
      const currentPath = state.pathname;
      if (currentPath === '/kakao-redirect/') {
        return;
      }
      router.replace('/login');
      dispatch({
        type: 'SET_HOSPITAL_NAME',
        payload: '',
      });
      loginDispatch({
        type: 'SET_LOGIN_FORM_TYPE',
        payload: 'signup',
      });
      dispatch({
        type: 'SET_HOSPITAL_KEY',
        payload: '',
      });
      dispatch({
        type: 'SET_USER_NAME',
        payload: '',
      });
      dispatch({
        type: 'SET_USER_INFO',
        payload: '',
      });
      setCurrentUser(null);
    }
    return () => {
      if (userSnapshot) {
        userSnapshot();
      }
    };
  }, [currentUser, isUserLoad]);
  // 인증 관리
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setUserLoad(true);
    });
    return () => unsubscribe();
  }, [dispatch]);

  return {
    currentUser,
    requestLogin,
    fetchErrorCode,
    handleLogout,
    requestPassword,
    token,
  };
};

// Page에 데이터 내려주기 -> 전역적으로 내려주어야 하므로 _app.tsx 고려
const AuthProvider: FC = ({ children }) => {
  const firebaseAuth = useFirebaseAuth();
  /*아래 계층의 컴포넌트를 랩으로 싸다*/
  return (
    <AuthContext.Provider
      value={{
        currentUser: firebaseAuth.currentUser,
        requestLogin: firebaseAuth.requestLogin,
        fetchErrorCode: firebaseAuth.fetchErrorCode,
        handleLogout: firebaseAuth.handleLogout,
        requestPassword: firebaseAuth.requestPassword,
        token: firebaseAuth.token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
