import type { MessageTemplateInitialDataInterface } from '@/types';

const MessageInitialData: MessageTemplateInitialDataInterface = {
  deleteAlertMessage: '',
  defaultAutoMessageTemplateList: [],
  defaultContentsMessageTemplateList: [],
  defaultQuickMessageTemplateList: [],
  contentsMessageTemplateList: [],
  autoMessageTemplateList: [],
  quickMessageTemplateList: [],
  visibleContentsMessageTemplateList: [],
  visibleAutoMessageTemplateList: [],
  visibleQuickMessageTemplateList: [],
  isShownMessageTemplatePopup: false,
  isShownAlert: false,
  isShownDeleteAlert: false,
  isShownLockAlert: false,
  messagePopupType: '등록',
  selectedMessageTemplateKey: '',
  keywordInputText: '',
  messageInputText: '',
  messageTemplatePopupTitle: '',
  textAreaFocus: false,
  isShownPublishAlert: false,
};

export { MessageInitialData };
