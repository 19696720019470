import { useReducer } from 'react';

import { SidebarContext } from './sidebarContext';
import { SidebarInitialData } from './SidebarInitialData';
import { sidebarReducer } from './sidebarReducer';

const SidebarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sidebarReducer, SidebarInitialData);
  const value = { state, dispatch };
  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

export default SidebarProvider;
