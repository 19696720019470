import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { VetfluxButton } from '@/atoms/button/VetfluxButton';
import { VetfluxArrowIcon } from '@/atoms/icons/VetfluxArrowIcon';
import NeulPetPlusLogo from '@/molecules/desktop/NeulPetPlusLogo';
import { doCopy } from '@/utils/doCopy';
import { isInAppBrowser } from '@/utils/userAgentChecker';
export const SafariIOSInstallGuide = () => {
  const iosSliderRef = useRef<Slider>();
  const [currentPosition, setCurrentPosition] = useState(0);
  const onChangeSlide = (index) => {
    setCurrentPosition(index);
  };
  return (
    <div
      className={
        'absolute top-0 z-50 w-full h-full flex flex-col items-center justify-center bg-white '
      }
    >
      <div className={'flex flex-col w-full items-center justify-center gap-4'}>
        <div className={'flex flex-col items-center'}>
          <img
            src={'/favicon/android-icon-192x192.png'}
            width={60}
            height={60}
            alt={'logo'}
          />
          <p
            className={
              'text-neutral-dark text-xl font-bold mx-10 mt-6 whitespace-pre-line text-center'
            }
          >
            {`늘펫 메신저 설치 가이드`}
          </p>
          <p
            className={
              'text-neutral-dark text-base mx-8 mt-5 whitespace-pre-line text-center'
            }
          >
            {`${
              isInAppBrowser()
                ? '해당 URL 주소를 복사하여\n사파리 브라우저로 이동해주세요.'
                : '홈 화면에 늘펫 메신저 바로가기를 추가하여\n보호자의 메시지를 실시간 알림으로 확인하세요.'
            }`}
          </p>
        </div>
        {isInAppBrowser() && (
          <VetfluxButton
            text={'주소 복사하기'}
            type={0}
            onClick={async () => {
              const success = await doCopy(window.location.origin);
              if (success) {
                alert(
                  '복사에 성공했습니다.\n사파리 앱을 켜서 다시 접속해주세요.',
                );
              } else {
                alert(
                  '복사에 실패하였습니다.\n주소창을 클릭 후 복사 해주세요.',
                );
              }
            }}
          />
        )}
        {!isInAppBrowser() && (
          <Slider
            className={'w-full h-full min-w-0 min-h-0'}
            infinite={false}
            ref={iosSliderRef}
            arrows={false}
            afterChange={onChangeSlide}
          >
            <div className={'w-full flex flex-col justify-center items-center'}>
              <img
                className={`${
                  screen?.orientation?.type?.includes('landscape')
                    ? 'w-1/2'
                    : 'w-full'
                } px-10`}
                src={'/a2hs/img_a2hs_safari_share.png'}
                alt={'a2hs_img'}
              />
              <p
                className={
                  'text-neutral-dark font-bold text-base mx-10 mt-6 text-center'
                }
              >
                step 01
              </p>
              <p
                className={
                  'text-neutral-dark text-base mx-10 mt-5 whitespace-pre-line text-center'
                }
              >
                {`브라우저 하단에 있는\n공유하기 버튼을 눌러주세요.`}
              </p>
            </div>
            <div className={'w-full flex flex-col justify-center items-center'}>
              <img
                className={`${
                  screen?.orientation?.type?.includes('landscape')
                    ? 'w-1/2'
                    : 'w-full'
                } px-10`}
                src={'/a2hs/img_a2hs_safari_add.png'}
                alt={'a2hs_img'}
              />
              <p
                className={
                  'text-neutral-dark font-bold text-base mx-6 mt-10 text-center'
                }
              >
                step 02
              </p>
              <p
                className={
                  'text-neutral-dark text-base mx-8 mt-5 whitespace-pre-line text-center'
                }
              >
                {`공유하기 버튼을 누르시면\n브라우저 메뉴가 펼쳐집니다.`}
              </p>
            </div>
            <div className={'w-full flex flex-col justify-center items-center'}>
              <img
                className={`${
                  screen?.orientation?.type?.includes('landscape')
                    ? 'w-1/2'
                    : 'w-full'
                } px-10`}
                src={'/a2hs/img_a2hs_safari_icon.png'}
                alt={'a2hs_img'}
              />
              <p
                className={
                  'text-neutral-dark font-bold text-base mx-6 mt-10 text-center'
                }
              >
                step 03
              </p>
              <p
                className={
                  'text-neutral-dark text-base mx-8 mt-5 whitespace-pre-line text-center'
                }
              >
                {`홈화면 추가를 누르신 후 디바이스의 홈에\n추가된 늘펫 메신저 앱을 통해 재접속 해주세요.\n메신저 로그인 화면으로 연결됩니다.`}
              </p>
            </div>
          </Slider>
        )}
        {!isInAppBrowser() && (
          <div className={'flex flex-col items-center mt-5'}>
            <div className={'flex flex-row items-center gap-3'}>
              {Array(3)
                .fill(0)
                .map((value, index) => {
                  return (
                    <div
                      key={`dot_index_${index}`}
                      className={`text-xl text-white w-3 h-3 rounded-full ${
                        currentPosition === index
                          ? 'bg-primary'
                          : 'bg-secondary-dark '
                      }`}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
