import * as amplitude from '@amplitude/analytics-browser';
import dayjs from 'dayjs';
import router from 'next/router';
import React, { useContext, useEffect, useState } from 'react';

import VetFluxModalCloseButton from '@/atoms/button/VetFluxModalCloseButton';
import { LoginContext } from '@/context/login/LoginContext';
import { requestKakaoLogin } from '@/service/kakaoService';
import { gtag } from '@/utils/tags';

const RegisterModal = ({ onCancelClick }) => {
  const [isClicked, setClicked] = useState(false);
  const { dispatch: loginDispatch } = useContext(LoginContext);
  useEffect(() => {
    if (isClicked) {
      setTimeout(() => {
        setClicked(false);
      }, 2500);
    }
  }, [isClicked]);
  const handleKakaoLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    setClicked(true);
    if (isClicked) {
      return;
    }
    router.push('/login/').then(() => {
      window.sessionStorage.setItem('loginType', 'demo');
      requestKakaoLogin();

      amplitude.track('Signup Complete', {
        authType: 'kakao',
        createdAt: dayjs().format('YYYY.MM.DD'),
      });
      gtag.event({
        action: 'Signup Complete',
        category: 'Basic',
        authType: 'kakao',
        createdAt: dayjs().format('YYYY.MM.DD'),
      });
    });
  };
  const handleEmailLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    setClicked(true);
    if (isClicked) {
      return;
    }
    router.push('/login/').then(() => {
      setTimeout(() => {
        loginDispatch({
          type: 'SET_LOGIN_FORM_TYPE',
          payload: 'email_signup',
        });
      }, 150);
    });
  };
  return (
    <div className='fixed z-50 w-full h-full left-0 top-0 bg-[#00000045]'>
      <div className='relative z-40 left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] bg-white w-[35rem] rounded-3xl py-9 px-8 text-neutral-dark mild-shadow max-h-[calc(100%-2.5rem)] overflow-y-auto'>
        <VetFluxModalCloseButton onCancelClick={onCancelClick} />
        <header className='flex items-center'>
          <div className='text-xl font-bold flex-1'>회원 가입 후 도입 신청</div>
        </header>
        <div
          className={
            'relative w-full flex-1 flex flex-col justify-center items-center lg:rounded-b-3xl px-[2rem]'
          }
        >
          <button
            data-login-type={'signup'}
            className={'bg-primary text-white rounded-md py-3 w-full mt-8'}
            onClick={handleEmailLogin}
          >
            회원가입
          </button>
          {/* <button
            data-login-type={'signup'}
            className={
              'bg-[#F7E600] text-neutral-dark rounded-md py-3 w-full mt-3'
            }
            onClick={handleKakaoLogin}
          >
            카카오 계정으로 회원가입
          </button> */}
          <p className={'text-xs text-neutral-dark text-center mt-3 mb-2'}>
            버튼을 클릭하면{' '}
            <a
              className={'text-primary'}
              href={
                'https://neulpet.notion.site/6f59dd62b5714a9eb8ca390c6623a0e0'
              }
              target={'_blank'}
              rel='noreferrer'
            >
              개인정보 수집
            </a>
            에 동의한 것으로 간주합니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegisterModal;
