import produce from 'immer';
import { isArray, isBoolean, isNumber, isObject, isString } from 'lodash';
import _ from 'lodash';

import { LoginInitialDataInterface } from '@/types';

import { LoginActionType } from './LoginActionType';

const loginReducer = (
  state: LoginInitialDataInterface,
  action: {
    type: LoginActionType;
    payload: unknown;
  },
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_EMAIL': {
        if (isString(action.payload)) {
          draft.email = action.payload;
        }
        break;
      }
      case 'SET_PASSWORD': {
        if (isString(action.payload)) {
          draft.password = action.payload;
        }
        break;
      }
      case 'SET_CONFIRM_PASSWORD': {
        if (isString(action.payload)) {
          draft.confirmPassword = action.payload;
        }
        break;
      }
      case 'SET_ERROR_MESSAGE': {
        if (isString(action.payload)) {
          draft.errorMessage = action.payload;
        }
        break;
      }
      case 'SET_LOGIN_FORM_TYPE': {
        if (
          action.payload === 'signup' ||
          action.payload === 'email_login' ||
          action.payload === 'email_signup' ||
          action.payload === 'introductory' ||
          action.payload === 'forgot_email' ||
          action.payload === 'account_merge'
        ) {
          draft.loginFormType = action.payload;
        }
        break;
      }
      case 'SET_VETFLUX_USER_KEY': {
        if (isString(action.payload)) {
          draft.vetfluxUserKey = action.payload;
        }
        break;
      }
      case 'SET_VETFLUX_USER_NAME': {
        if (isString(action.payload)) {
          draft.vetfluxUserName = action.payload;
        }
        break;
      }
      case 'SET_VETFLUX_USER_ROLE': {
        if (isString(action.payload)) {
          draft.role = action.payload;
        }
        break;
      }
      case 'SET_VETFLUX_USER_PHONE_NUMBER': {
        if (isString(action.payload)) {
          draft.phoneNumber = action.payload;
        }
        break;
      }
      case 'SET_IS_CONFIRM_SUBSCRIPTION': {
        if (isBoolean(action.payload)) {
          draft.isConfirmSubscription = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_KEY': {
        if (isString(action.payload)) {
          draft.hospitalKey = action.payload;
        }
        break;
      }
      case 'SET_HOSPITAL_NAME': {
        if (isString(action.payload)) {
          draft.hospitalName = action.payload;
        }
        break;
      }
      case 'SET_AUTH_COMPLETED': {
        if (isBoolean(action.payload)) {
          draft.isAuthCompleted = action.payload;
        }
        break;
      }
      case 'SET_TOKEN':
        if (isString(action.payload)) {
          draft.token = action.payload;
        }
        break;
      default:
        break;
    }
  });
};

export { loginReducer };
