import { createContext, Dispatch } from 'react';

import { LoginInitialDataInterface } from '@/types';

import { LoginActionType } from './LoginActionType';

export const LoginContext = createContext<
  | {
      state: LoginInitialDataInterface;
      dispatch: Dispatch<{ type: LoginActionType; payload: unknown }>;
    }
  | undefined
>(undefined);
